import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/LayoutTwo"
import { Helmet } from "react-helmet"

class Sostienici extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Italian Art Trust O.N.L.U.S. - Sostienici</title>
          <meta
            name="description"
            content="A clear summary and instructions on how to contribute to the Italian Art Trust, as well as a form for potential donors to share private data for legal and tax purposes."
          />
        </Helmet>
        <Container>
          <section className="section" id="sostienici">
            <div className="section__wrap">
              <h1 className="section__header">SOSTIENICI</h1>
              <Row className="sostienici__wrap">
                <Col md={6}>
                  <h3 className="sostienici__header">COME CONTRIBUIRE</h3>
                  <p>
                    I fondi raccolti saranno destinati al finanziamento e alla
                    valorizzazione della giovane arte contemporanea, con
                    l’obiettivo di facilitarne l’esposizione al pubblico e la
                    sua diffusione.
                  </p>
                  <p>
                    Se desideri dare il tuo contributo per sostenere ITALIAN ART
                    TRUST ONLUS, ti chiediamo per favore di compilare il modulo
                    che trovi in questa pagina ed effettuare un bonifico
                    bancario. Puoi trovare tutte le informazioni per effettuare
                    il bonifico bancario nella pagina che verrá mostrata dopo
                    avere compilato il modulo.
                  </p>
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <h3 className="sostienici__header">MODULO PER CONTRIBUTO</h3>
                  <form
                    method="post"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    name="contact"
                    className="contact-form"
                    action="/grazie"
                  >
                    <input type="hidden" name="bot-field" />
                    <input
                      type="hidden"
                      name="form-name"
                      value="contact"
                      required
                    />

                    <label className="form__label">
                      Nome:
                      <input
                        type="text"
                        name="nome"
                        className="fielder"
                        required
                      />
                    </label>

                    <label className="form__label">
                      Cognome:
                      <input
                        type="text"
                        name="cognome"
                        className="fielder"
                        required
                      />
                    </label>

                    <label className="form__label">
                      Email:
                      <input
                        type="email"
                        name="email"
                        className="fielder"
                        required
                      />
                    </label>

                    <label className="form__label">
                      Codice Fiscale:{" "}
                      <input
                        name="codicefiscale"
                        className="fielder"
                        minlength="16"
                        required
                      />
                    </label>

                    <label className="form__label">
                      Città:
                      <input name="citta" className="fielder" />
                    </label>

                    <label className="form__label">
                      Indirizzo:
                      <input name="indirizzo" className="fielder" />
                    </label>

                    <Row>
                      <Col md={1}>
                        <p>
                          <input
                            type="checkbox"
                            name="privacy"
                            value="checked"
                            required
                          ></input>
                        </p>
                      </Col>
                      <Col md={11}>
                        <p>
                          Dichiaro di aver preso visione delle informazioni
                          relative al{" "}
                          <a href="/privacy">
                            trattamento dei miei dati personali
                          </a>{" "}
                          e fornisco il relativo consenso.
                        </p>
                      </Col>
                    </Row>

                    <p>
                      <button type="submit" className="IAT__button">
                        Procedi al trasferimento
                      </button>
                    </p>
                  </form>
                </Col>
              </Row>
              <Row>
                <p className="sostienici__footnote">
                  Le persone fisiche e giuridiche che vorranno, mediante
                  erogazioni liberali, sostenere le iniziative di Italian Art
                  Trust O.N.L.U.S potranno beneficiare delle agevolazioni
                  fiscali previste dalla normativa vigente.
                </p>
              </Row>
            </div>
          </section>
        </Container>
      </Layout>
    )
  }
}

export default Sostienici
